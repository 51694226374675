/* dashboard cards */
 .dashcard{
    background-color: var(--white);
    padding: 20px;
    border-radius: var(--br_r);
 }
 .dashiconbox{
    padding: 16px;
    border-radius: var(--br_r);
    margin-right: 10px;
 }
 /* color of boxes */
 .dashred{
   background-color: var(--bg_red);
   color: var(--t_red);
 }
 .dashyellow{
   background-color: var(--bg_yellow);
   color: var(--t_yellow);
 }
 .dashgreen{
   background-color: var(--bg_green);
   color: var(--t_green);
 }
 .dashpurple{
   background-color: var(--bg_purple);
   color: var(--t_purple);
 }
 .dashblue{
   background-color: var(--bg_blue);
   color: var(--t_blue);
 }
 .dashlightblue{
   background-color: var(--bg_light_blue);
   color: var(--t_light_blue);
 }
 /* color of boxes ends */
 .dashicons{
    width: 33px;
    height: 33px;
 }
 .dashcount{
    color: #2B2B2B;
    font-family: var(--font);
    font-size: 35px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
 }
 .dashname{
   color: #2B2B2B;
   font-family: var(--font);
   font-size: 12px;
   font-style: normal;
   font-weight: 500;
   line-height: normal;
 }
 .charts{
  background-color: var(--white);
  border-radius: var(--br_r);
  padding: 10px;
 }
/* dashboard cards ends */