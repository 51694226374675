.dashcard {
  background-color: var(--white);
  border-radius: var(--br_r);
  padding: 20px;
}

.dashiconbox {
  border-radius: var(--br_r);
  margin-right: 10px;
  padding: 16px;
}

.dashred {
  background-color: var(--bg_red);
  color: var(--t_red);
}

.dashyellow {
  background-color: var(--bg_yellow);
  color: var(--t_yellow);
}

.dashgreen {
  background-color: var(--bg_green);
  color: var(--t_green);
}

.dashpurple {
  background-color: var(--bg_purple);
  color: var(--t_purple);
}

.dashblue {
  background-color: var(--bg_blue);
  color: var(--t_blue);
}

.dashlightblue {
  background-color: var(--bg_light_blue);
  color: var(--t_light_blue);
}

.dashicons {
  width: 33px;
  height: 33px;
}

.dashcount {
  color: #2b2b2b;
  font-family: var(--font);
  font-size: 35px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.dashname {
  color: #2b2b2b;
  font-family: var(--font);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.charts {
  background-color: var(--white);
  border-radius: var(--br_r);
  padding: 10px;
}

/*# sourceMappingURL=index.5ab6253d.css.map */
